import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import '../assets/css/style.css';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../lib/apolloClient';
import { AppContext } from '../lib/context';
import fetchMe from '../lib/queries/fetchMe';
import { useEffect, useState } from 'react';
import { parseCookies } from 'nookies';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('1012012602945259');
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });

    const jwt = parseCookies().jwt;
    if (jwt) {
      apolloClient.query({ query: fetchMe }).then((res) => {
        setUser(res.data.me);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const handleRouteChange = (url) => {
    window.gtag('config', 'UA-49016719-1', {
      page_path: url,
    });
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (loading === false && user && user.firstName === null && router.pathname != '/profile') {
      router.push('/profile');
    }
  });

  // useEffect(() => {
  //   const handleStart = (url) => {
  //     console.log(`Loading: ${url}`);
  //     NProgress.start();
  //   };
  //   const handleStop = () => {
  //     NProgress.done();
  //   };

  //   router.events.on("routeChangeStart", handleStart);
  //   router.events.on("routeChangeComplete", handleStop);
  //   router.events.on("routeChangeError", handleStop);

  //   return () => {
  //     router.events.off("routeChangeStart", handleStart);
  //     router.events.off("routeChangeComplete", handleStop);
  //     router.events.off("routeChangeError", handleStop);
  //   };
  // }, [router]);

  return (
    <ApolloProvider client={apolloClient}>
      <AppContext.Provider
        value={{
          user: user,
          setUser: setUser,
          loading: loading,
          isAuthorized: !!user,
        }}
      >
        <Head>
          <title>Sacred Fig</title>
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />

          {/* Import CSS for nprogress */}
          <link rel="stylesheet" type="text/css" href="/css/nprogress.css" />
        </Head>
        <Component {...pageProps} />
      </AppContext.Provider>
    </ApolloProvider>
  );
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
// MyApp.getInitialProps = async (ctx) => {
//   // Calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(ctx);
//   // Fetch global site settings from Strapi
//   const global = await fetchAPI("/global");
//   // Pass the data to our page via props
//   return { ...appProps, pageProps: { global } };
// };

export default MyApp;
